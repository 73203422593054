.full-height {
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: hidden;
}

.fill-height {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}
